const organizationOwnership = {
  BG: "ИП",
  ИП: "ИП",
  ПАО: "ПАО",
  НАО: "НАО",
  ООО: "ООО",
  НО: "НО",
  МУ: "МУ",
  MADOU: "МАДОУ",
  MKDOU: "МКДОУ",
  MBDOU: "МБДОУ",
  MBOU: "МБОУ",
  MAUD: "МАУД",
  MDOU: "МДОУ",
  MOU: "МОУ",
  MBU: "МБУ"
};
export default organizationOwnership;
